import React, { useState } from "react";

import { Td,Td2, Tr, TableContainer, Table } from "../Styles/TableStyling";

import JobInfo from "./JobInfo";
import styled from "styled-components";
const FormButton = styled.button`

background-color: #ffa500;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  margin-right:5px;
  margin-bottom:0px;
  padding: 10px 20px;
  font-size: small;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff8000;
  }
`;
const EmployeeTable = ({
  
  keyField,
  setState

}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckBoxChange = (item) => {
    if (!selectedItems.includes(item[keyField])) {
      setSelectedItems([...selectedItems, item[keyField]]);
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item[keyField])
      );
    }
  };

  return (
    <TableContainer>
      {/* <Dropdown buttonText="Open Dropdown"></Dropdown> */}
      <Table>
        <thead>
        </thead>

        <tbody>
          <Tr>
            <Td>
            <JobInfo title="Pre Sales Engineer"time="Posted today" setState={setState}/>
            </Td>
            <Td2>
              On-site
            </Td2><Td>
              Lahore,Punjab,Pakistan
            </Td><Td>
              Sales Engineering office
            </Td>
            <Td>
              Full-time
            </Td>
          </Tr>
          <Tr>
            <Td>
            <JobInfo title="Head Of Marketing"time="Posted today"/>
            </Td>
            <Td2>
              On-site
            </Td2><Td>
              Lahore,Punjab,Pakistan
            </Td><Td>
              Sales Engineering office
            </Td>
            <Td>
              Full-time
            </Td>
          </Tr>
          <Tr isLast={true}>
            <Td>
            <JobInfo title="Supply Chain Manager and Team Lead"time="Posted today"/>
            </Td>
            <Td2>
              On-site
            </Td2><Td>
              Lahore,Punjab,Pakistan
            </Td><Td>
              Sales Engineering office
            </Td>
            <Td>
              Full-time
            </Td>
          </Tr>
          

          
        </tbody>
       
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
  <FormButton>
    Show more
  </FormButton>
</div>

    </TableContainer>
  );
};

export default EmployeeTable;
