import React from "react";
import styled from "styled-components";

const SearchInput = styled.input`
  padding: 15px;
  border: 1px solid #black;
  border-radius: 10px;
  width: 97%; 
  margin-bottom: 0px;
  
  @media screen and (max-width: 845px) {
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
   
    margin-bottom: 10px;
  }
`;


const SearchBar = ({ onSearch }) => {
  return (
    <position>

      <SearchInput
        type="text"
        placeholder="Search Jobs"
        // onChange={(e) => onSearch(e.target.value)}
      />
    </position>
  );
};

export default SearchBar;
