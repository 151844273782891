import React from "react";
import styled from "styled-components";



export const Td = styled.td`
  padding: 0px;
  white-space: nowrap;

  border-top: 0px solid #ededed;

`;

export const JobContainer = styled.div`
  display: flex;
  flex-direction: column;
  &:hover {
    span {
      color: #ffa500;
    }
  }
`;

const JobTitle = styled.span`
  text-align: left;
  color: #6e6b7b;
  font-weight: 700;
  font-size: 20px;
  color:#ffa500;
`;

const PostedTime = styled.span`
  font-size: 16px;
  color: grey;
  text-align: left;
`;

const JobInfo = ({ title, time, setState }) => {


  return (
    <tr>
      <Td>
        <div style={{ display: "flex", alignItems: "center" }}>
            
          <JobContainer>
            <JobTitle onClick={() => setState(false)}>{title}</JobTitle> 
            <PostedTime>{time}</PostedTime> 
          </JobContainer>
        </div>
      </Td>
    </tr>
  );
};

export default JobInfo;
