import { styled } from "styled-components";
import { Link } from "react-router-dom";
import Select from "react-select";
// import SearchBar from "../components/searchbar";
export const Footer=styled.div`
background: white;
  display: flex;
  justify-content: center; 
  align-items: center; 
  text-align: center;
  height: 50px;
  border-top: 1px solid grey;
`
export const UploadArea = styled.div`
  width: 100%;
  background: white;
  padding: 15px;
  border: 1px dashed black;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
`;


export const UploadText = styled.p`
  font-size: 14px;
  color: #888;
  margin: 0;
`;
export const StyledButton = styled.button`
  background-color: transparent;
  border: none;

  margin-top: 1px;
  margin-right: 5px;
  padding: 15px 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight:bold;
  transition: background-color 0.3s ease;

  ${(props) =>
    props.currentPage === props.page
      ? `
     background-color: transparent;
    color: #ffa500;
    border-bottom: 2px solid #ffa500; 
  `
      : ""}
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-item: center;
  position: sticky;
  top: 0;
  border-top: 0.5px solid #ebf0f7;
  @media (max-width: 845px) {
    flex-direction: column;
    align-items: left;
  }
`;

export const HeadingBox = styled.div`
  position: relative;
  background: white;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;
export const Box = styled.div`
  position: relative;
  background: #f3f5f8;

  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
`;
export const ApplicationInnerBox = styled.div`
  position: relative;
  background: #ebf0f7;
  border-radius: 5px;
  padding: 10px 20px;
  height: fit-content;
`;
export const ContentWrapper = styled.div`
  width: 75%;
  margin-bottom:20px;
`;
export const ContentWrapperApplication = styled.div`
  width: 40%;
`;

export const Heading = styled.h1`
  display: flex;
  margin: 0 auto;
  background: linear-gradient(to right, #ffa500, #ffd000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 65px;
`;
export const H3 = styled.h3`
  text-align: center;

  font-weight: normal;
  margin-top: 0px;
`;
export const H1 = styled.h1`
  text-align: center;

  font-weight: normal;
  margin-top: 0px;
  font-size: 28px;
`;

export const LeftColumn = styled.div`
  flex: 2;
  padding-right: 20px;
  margin-right: 30px;

  @media (max-width: 845px) {
    padding-right: 0;
  }
`;

export const RightColumn = styled.div`
  flex: 1;
  padding-left: 10px;

  @media (max-width: 845px) {
    padding-left: 0;
  }
`;
export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 845px) {
    flex-direction: column;
  }
`;
export const FormLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
`;
export const FormTextarea = styled.textarea`
  width: 100%;
  padding: 15px;
  border: 1px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 13px;
  border: 1px solid black;
  border-radius: 10px;
  box-sizing: border-box;
`;

export const FormHalfInput = styled.input`
  flex: 1;
  padding: 13px;
  width: 100%;
  border: 1px solid black;
  border-radius: 10px;
  box-sizing: border-box;
`;
export const CustomText = styled.span`
  color: gray;
  font-size: small;
`;
export const ApplicationHeading = styled.h1`
  font-weight: normal;
  font-size: 26px;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 10px 0;
  input[type="checkbox"] {
    margin-top: 15px;
  }
`;
export const RedAsterisk = styled.span`
  color: red;
  margin-right: 5px;
`;

export const Line = styled.div`
  position: relative;
  width: 100%;
  height: 0.009rem;
  background-color: grey;
`;
export const ProgressBarWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999; 
`;
export const AutoFillBox = styled.div`
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 30px;
  margin-top: 20px;
`;
export const FormButton = styled.button`

background-color: #ffa500;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  margin-right:5px;
  
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  width:100%;
  margin-bottom:20px;
 
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff8000;
  }
`;
export const SaveButton = styled.button`

background-color: #ffa500;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  margin-right:5px;
  
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  width:15%;
  margin-bottom:20px;
 
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff8000;
  }
`;

export const CancelButton = styled.button`

background-color:transparent;
  color: black;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  margin-right:5px;
  
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  width:15%;
  margin-bottom:20px;
 
  transition: background-color 0.3s ease;

  &:hover {
    // background-color: #ff8000;
  }
`;
export const CreateEmployeeHeading = styled.h6`
  margin-bottom: 0px;
  width: 100%;
`;

export const Th = styled.th`
  background: #f3f2f7;
  color: #6e6b7b;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  height: 30px;
  top: 10%;
//   padding: 9px 69.4px;
  font-weight: medium;
  width: 100%;
  font-size: 0.7rem;
  text-align: center;

  white-space: nowrap; 

  @media screen and (min-width: 845px) {
  }
`;

export const Tr = styled.tr`
  margin: 100px;
  border-bottom: ${({ isLast }) => isLast ? 'none' : '1px solid grey'}; 
  & > :first-child {
    width: 10px;
  }
  
  
`;
export const Td = styled.td`
   padding: 40px 15px;

  white-space: nowrap;
  width: 100%;
  text-align: center;

 
  vertical-align: center;
  color: #6e6b7b;
  font-weight: medium;
  font-size: 1.1rem;
`;
export const Td2 = styled.td`
   padding: 40px 15px;

  white-space: nowrap;
  width: 100%;
  text-align: center;


  vertical-align: center;
  color: #6e6b7b;
  font-weight: bold;
  font-size: 1.1rem;
`;

export const Table = styled.table`

  margin-top: 30px;
  width: 100%;
  height:100%;
  overflow-x: auto;
  backgorund-color: #ffa500;

  border-collapse: collapse;

  border: 0px solid #ccc;

  @media screen and (max-width: 845px) {
    width: 100%;
    margin-top: 10px;
  }
`;
export const AddEmployeeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 845px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 9px 0px;
    margin-right: 0px;

    width: 100%;
  }
`;

export const SuccessBadge = styled.span`
  background-color: rgba(40, 199, 111, 0.12) !important;
  color: #28c76f !important;
  padding: 5px 14px;
  border-radius: 4px;
`;

export const DangerBadge = styled.span`
  background-color: #ea54542c;
  color: #ea5455;
  padding: 4px 12px;
  border-radius: 4px;
`;

export const UserImage = styled.img`
  width: 39px;
  height: 31px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -4px;
`;
export const BoxContainer = styled.div`
  
  padding: 3px 0px;
  
  border-radius: 5px;
  background: #ffffff;
  margin-top: 20px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  margin-bottom:10px;
`;

export const AddEmployeeButton = styled(Link)`
  background-color: #ffa500;
  color: #fff;
  padding: 7px 40px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  margin-left: 0px;

  margin-top: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff8000;
  }
  @media screen and (max-width: 845px) {
    padding: 7px 10px;
    min-width: 10px;
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
  }
`;

export const HeadingAndSearchContainer = styled.div`
  display: flex;
  padding: 7px 40px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 845px) {
    flex-direction: column;

    padding: 10px 20px;
    margin-right: 10px;
  }
`;

// export const StyledSearchBar = styled(SearchBar)`
//   width: 10%;
//   @media screen and (max-width: 845px) {
//     width: 100%;
//     margin-bottom: 10px;
//   }
// `;

export const TableContainer = styled.div`
  overflow-x: auto;
  // box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
//   background:black;
margin-bottom:20px;
`;

export const CenteredContainer = styled.div`
  position: absolute;
   top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 10px;
  margin-bottom: 10px; 

  border-radius: 5px;
  width: 100%;
     height: 100%; 


  @media (max-width: 845px) {
    width: 100%;
    left: 0;
    right: 0;
  }

  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
`;


export const EntriesDropdown = styled(Select)`
  width: ${(props) => (props.width ? props.width : "150px")};
  margin-right: 8px;

  .select__control {
    border: 1px solid orange;
    border-radius: 5px;
   
    transition: border-color 0.3s ease;

    &:hover {
      border-color: orange;
    }

    &:focus {
      outline: none;
      border-color: orange;  
      box-shadow: 0 0 0 3px rgba(255, 165, 0, 0.5);
    }
  }
  @media screen and (max-width: 845px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    flex: 1;
    margin-right: 13px;
  }

  & > *:last-child {
    margin-right: 0;
  }

  @media (max-width: 845px) {
    flex-direction: column;
    width: 100%;
    & > * {
      margin-right: 0;
    }
  }
`;

export const FilterOuterBox = styled.div`
  display: flex; 
margin-bottom:30px;
  & > *:first-child {
    margin-left: 0px; 
  }
  & > *:last-child {
    margin-right: 0px !important;
  }

  @media (max-width: 845px) {
    padding: 5px 20px;
    flex-direction: column;
    & > * {
      margin-right: 0;
    }
  }
`;

export const FilterContainer = styled.div`
  background: #fff;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  @media (max-width: 845px) {
    & > * {
      margin-right: 0;
    }
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

export const dropDownStyle = {
  menu: (provided) => ({
    ...provided,
    background: "#ffffff",
    border: provided.isFocused ? "1px solid orange" : "1px solid #ccc",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "white" : "white",
    color: state.isSelected ? "black" : "black",
    "&:hover": {
      backgroundColor: "#ffa500",
      color: "white",
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
};
