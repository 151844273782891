
import "./App.css";
import MainPage from "./Pages/MainPage";
import Description from "./Pages/DescriptionPage";
import { useState } from "react";


function App() {
  const [state, setState] = useState(true);
  return (
    <>
    {state &&  <MainPage setState={setState}/>}
    {!state && <Description/>}
      
    </>
  );
}

export default App;
