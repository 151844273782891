import React, { useState, useRef, useEffect } from "react";

import styled from "styled-components";

import { MdUpload,MdDelete } from "react-icons/md";
import Progressbar from "../Components/ProgressBar";
import { BsLightningChargeFill } from "react-icons/bs";
import {
  CancelButton,
  CenteredContainer,
  FormButton,
  SaveButton,
  Box,
  ContentWrapper,
  ColumnContainer,
  LeftColumn,
  RightColumn,
  ProgressBarWrapper,
  CustomText,
  ApplicationHeading,
  ApplicationInnerBox,
  RedAsterisk,
  FormHalfInput,
  FormInput,
  FormLabel,
  ContentWrapperApplication,
  AutoFillBox,
  Line,
  FormTextarea,
  Heading,
  HeadingBox,
  H1,
 
  ButtonContainer,
  StyledButton,
  CheckboxContainer,
  UploadArea,
  UploadText,
  Footer,
} from "../Styles/TableStyling";
export const UploadIcon = styled(MdUpload)`
  font-size: 48px;
  margin-bottom: 10px;
  color: #ffa500;
  cursor: pointer;
`;

const DesciptionPage = () => {
  return (
    <Box>
      <ContentWrapper>
        <ColumnContainer>
          <LeftColumn>
            <h3>Desciption</h3>
            <t>
              Devsinc, a leading software development agency, is on the lookout
              for an exceptional Pre-Sales Engineer to join our team. This role
              will report directly to the VP of Sales Engineering. Devsinc
              specializes in delivering a range of technology solutions through
              staff augmentation, dedicated teams, and complete software
              outsourcing. Our growth has been fueled by organic success and
              referrals, amassing tens of millions in revenue.
            </t>
          </LeftColumn>
          <RightColumn>
            {" "}
            <h3>Desciption</h3>
            <strong>Qualifications:</strong>
            <ul>
              <li>
                Education: Bachelor’s degree in Computer Science, Engineering,
                or a related field.
              </li>
              <li>
                Experience: Minimum of 5 years of experience in a technical
                sales, presales, or solutions engineering role.
              </li>
              <li>
                Communication: Excellent verbal and written communication
                skills, with the ability to explain complex technical concepts
                to diverse audiences.
              </li>
              <li>
                Problem-Solving: Strong analytical and problem-solving skills,
                with the ability to think creatively and develop innovative
                solutions.
              </li>
              <li>
                Customer-Focused: Ability to build strong relationships with
                customers and understand their technical needs and challenges.
              </li>
              <li>
                Team Player: Collaborative and able to work effectively with
                cross-functional teams.
              </li>
            </ul>
            <strong>Benefits:</strong>
            <ul>
              <li>
                Competitive salary with a performance-based incentive plan.
              </li>
              <li>Comprehensive benefits package.</li>
              <li>
                Opportunities for professional growth and advancement within a
                supportive and innovative environment.
              </li>
              <li>Vacation policy and a collaborative workplace culture.</li>
            </ul>
          </RightColumn>
        </ColumnContainer>
        <div style={{ display: "flex", justifyContent: "center" }}>
        <FormButton style={{width:'50%'}}>Apply for this Job</FormButton>
        </div>
      </ContentWrapper>
    </Box>
  );
};

const ApplicationForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFormEdu, setShowFormEdu] = useState(false);
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file);
    }
  };
  const [ProgressBarPercent, setProgressBarPercent] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollY = window.scrollY;

      const scrollPercent = (scrollY / (documentHeight - windowHeight)) * 100;
      setProgressBarPercent(scrollPercent);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <ProgressBarWrapper>
        <Progressbar scrollProgress={ProgressBarPercent} />
      </ProgressBarWrapper>
      <Box>
        <ContentWrapperApplication>
          <AutoFillBox>
            <ColumnContainer>
              <LeftColumn style={{ flex: "1" }}>
                <FormLabel
                  style={{
                    marginTop: "0px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <BsLightningChargeFill />
                  AUTOFILL APPLICATION
                </FormLabel>
                <CustomText>
                  Save time by importing your resume in one of the following
                  formats: .pdf, .doc, .docx, .odt, or .rtf.
                </CustomText>
              </LeftColumn>
              <RightColumn>
                <FormButton>Upload Resume </FormButton>
              </RightColumn>
            </ColumnContainer>
          </AutoFillBox>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ApplicationHeading>Personal Information</ApplicationHeading>
            <CancelButton
              onClick={() => {
                // setShowForm(false);
                // setShowFormEdu(false);
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                fontSize: "16px",
                color: "grey",
                // textDecoration: 'underline'
              }}
            >
              <MdDelete /> Clear
            </CancelButton>
          </div>
          <Line />
          <ColumnContainer>
            <LeftColumn style={{ flex: "1", marginRight: "5px" }}>
              <FormLabel>
                <RedAsterisk>*</RedAsterisk>First Name
              </FormLabel>
              <FormHalfInput />
            </LeftColumn>
            <RightColumn>
              {" "}
              <FormLabel>
                <RedAsterisk>*</RedAsterisk>Last Name
              </FormLabel>
              <FormHalfInput />
            </RightColumn>
          </ColumnContainer>
          <FormLabel>
            <RedAsterisk>*</RedAsterisk>Email
          </FormLabel>
          <FormInput />
          <FormLabel>Phone Number</FormLabel>
          <FormInput type="text" placeholder={" +92"} />
          <CustomText>
            The hiring team may use this number to contact you about this job.
          </CustomText>
          <FormLabel>HeadLine</FormLabel>
          <FormInput />
          <FormLabel>Address</FormLabel>
          <FormInput />
          <CustomText>
            nclude your city, region, and country, so that employers can easily
            manage your application.
          </CustomText>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ApplicationHeading>Profile</ApplicationHeading>
            <CancelButton
              onClick={() => {
                setShowForm(false);
                setShowFormEdu(false);
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                fontSize: "16px",
                color: "grey",
                // textDecoration: 'underline'
              }}
            >
              <MdDelete /> Clear
            </CancelButton>
          </div>
          <Line />
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormLabel>Education</FormLabel>
              <SaveButton
                style={{
                  background: "none",
                  border: "1.5px solid #ffa500",
                  color: "#ffa500",
                }}
                onClick={() => setShowFormEdu(true)}
              >
                + Add
              </SaveButton>
            </div>
            {showFormEdu && (
              <ApplicationInnerBox>
                <FormLabel>
                  <RedAsterisk>*</RedAsterisk>School
                </FormLabel>
                <FormInput />
                <FormLabel>Field of Study</FormLabel>
                <FormInput />
                <FormLabel>Education</FormLabel>
                <FormInput />
                <FormLabel>Start Date </FormLabel>
                <FormInput type="date" />
                <FormLabel>End Date </FormLabel>
                <FormInput type="date" />

                <SaveButton>Save</SaveButton>
                <CancelButton onClick={() => setShowFormEdu(false)}>
                  Cancel
                </CancelButton>
              </ApplicationInnerBox>
            )}
          </div>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormLabel>Experience</FormLabel>
              <SaveButton
                style={{
                  background: "none",
                  border: "1.5px solid #ffa500",
                  color: "#ffa500",
                }}
                onClick={() => setShowForm(true)}
              >
                + Add
              </SaveButton>
            </div>
            {showForm && (
              <ApplicationInnerBox>
                <FormLabel>
                  <RedAsterisk>*</RedAsterisk>Title
                </FormLabel>
                <FormInput />
                <FormLabel>Company</FormLabel>
                <FormInput />
                <FormLabel>Industry</FormLabel>
                <FormInput />
                <FormLabel>Summary </FormLabel>
                <FormTextarea />

                <FormLabel>Start Date </FormLabel>
                <FormInput type="date" />
                <FormLabel>End Date </FormLabel>
                <FormInput type="date" />
                <CheckboxContainer>
                  <input type="checkbox" />
                  <FormLabel>I currently work here</FormLabel>
                </CheckboxContainer>
                <SaveButton>Save</SaveButton>
                <CancelButton onClick={() => setShowForm(false)}>
                  Cancel
                </CancelButton>
              </ApplicationInnerBox>
            )}
          </div>
          <div>
            <FormLabel>
              <RedAsterisk>*</RedAsterisk>Resume
            </FormLabel>
            <UploadArea>
              <UploadIcon onClick={handleUploadClick} />
              <UploadText>Upload your resume or drag it over</UploadText>
            </UploadArea>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
          <FormLabel>Summary</FormLabel>
          <FormTextarea rows="5" />
          <ApplicationHeading>Details</ApplicationHeading>
          <Line />
          <FormLabel>Cover Letter</FormLabel>
          <FormTextarea rows="5" />
          <FormButton>Submit Application</FormButton>
        </ContentWrapperApplication>
      </Box>
    </>
  );
};

const Description = ({}) => {
  const [currentPage, setCurrentPage] = useState("DescriptionPage");

  const renderContent = () => {
    if (currentPage === "DescriptionPage") {
      return (
        <>
          <DesciptionPage />
        </>
      );
    } else if (currentPage === "ApplicationForm") {
      return (
        <>
          <ApplicationForm />
        </>
      );
    }
  };

  return (
    <CenteredContainer>
      <HeadingBox>
        <Heading> RTA</Heading>
        <H1>Pre-Sales Engineer</H1>
        <CustomText>
          On-site: Lahore, Punjab, Pakistan Sales Engineering Office Full time
        </CustomText>
      </HeadingBox>

      <ButtonContainer>
        <StyledButton
          currentPage={currentPage}
          page="DescriptionPage"
          onClick={() => setCurrentPage("DescriptionPage")}
        >
          OVERVIEW
        </StyledButton>

        <StyledButton
          currentPage={currentPage}
          page="ApplicationForm"
          onClick={() => setCurrentPage("ApplicationForm")}
        >
          APPLICATION
        </StyledButton>
      </ButtonContainer>

      {renderContent()}
      <Footer>
      <FormLabel style={{color:'#ffa500',marginTop:'10px'}}>
          View Webbsite • View All Jobs
        </FormLabel>
        
      </Footer> 
      <Footer>
        <FormLabel style={{color:'#ffa500',marginTop:'10px'}}>
        <span style={{ fontWeight: 'normal' }}>Powered By </span>RTA • Accessibility
        </FormLabel>
        
      </Footer> 
    </CenteredContainer>
  );
};

export default Description;
