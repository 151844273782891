import React from "react";
import styled from "styled-components";
import Select from "react-select";

const FilterBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  // background:black;
  // height:100px;
`;

const Dropdown = styled(Select)`
  width: ${(props) => props.width || "100%"};
  max-width: 450px;
  //  height:100px;
  // background:black;
  margin-right: ${(props) => props.marginRight || "10px"};

  .select__control {
    border: 1px solid orange;
    border-radius: 5px;
    
    transition: border-color 0.3s ease;
    padding: 10px;
    &:hover {
      border-color: orange;
    }

    &:focus {
      outline: none;
      border-color: orange;
      box-shadow: 0 0 0 3px rgba(255, 165, 0, 0.5);
    }
  }

  .select__menu {
    z-index: 1;
  }
`;

const Box = styled.div`
  width: ${(props) => props.width || "100%"};
  @media screen and (max-width: 845px) {
    width: 100%;
  }
  
`;
const Title = styled.h6`
  font-size: 13px;
  margin-bottom: 3px;
  margin-left: 3px;
`;

const FilterBox = ({
  options,
  title,
  selectedValue,
  onValueChange,
  width,
  marginRight,
}) => {
  const handleDropdownChange = (selectedOption) => {
    if (selectedOption.label === selectedValue.label) return;
    onValueChange(selectedOption);
  };

  return (
    <Box width={width}>
      <Title>{title}</Title>
      <FilterBoxContainer>
        <Dropdown
          width={width}
          marginRight={marginRight}
          value={{
            value: selectedValue.value,
            label: selectedValue.label,
          }}
          onChange={(selectedOption) => handleDropdownChange(selectedOption)}
          options={options}
          styles={{
            menu: (provided) => ({
              ...provided,
              background: "#ffffff",
              border: provided.isFocused
                ? "1px solid orange"
                : "1px solid #ccc",
              fontSize: "20px",
              
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? "white" : "white",
              color: state.isSelected ? "black" : "black",
              "&:hover": {
                backgroundColor: "#ffa500",
                color: "white",
              },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "16px",
            }),
          }}
        />
      </FilterBoxContainer>
    </Box>
  );
};

export default FilterBox;
