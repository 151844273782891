import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px;
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background-color: #eee;
  margin-bottom: 10px;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  width: ${({ filled }) => filled}%;
  background-color: orange;
  transition: width 0s ease-in-out; 
`;

const ProgressBarPercent = styled.span`
  font-weight: 600;
  position: absolute;
  display:none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
`;

export default function Progressbar({ scrollProgress }) {
  const [filled, setFilled] = useState(0);

  useEffect(() => {
    setFilled(scrollProgress);
  }, [scrollProgress]);

  return (
    <Container>
      <ProgressBarWrapper>
        <ProgressBarFill filled={filled} />
        <ProgressBarPercent>{Math.round(filled)}%</ProgressBarPercent>
      </ProgressBarWrapper>
    </Container>
  );
}
