import React, { useState } from "react";
import EmployeeTable from "../Components/Table";
import { CenteredContainer, FilterOuterBox } from "../Styles/TableStyling";
import styled from "styled-components";
import SearchBar from "../Components/SearchBar";
import FilterBox from "../Components/FilterBox";
import { RxCross1 } from "react-icons/rx";
import { Footer,FormLabel } from "../Styles/TableStyling";
const HeadingBox = styled.div`
  position: relative;
  background: white;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
`;
const Box = styled.div`
  position: relative;
  background: #fbfbfb;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;

  flex-direction: column;
`;
const ContentWrapper = styled.div`
  width: 75%;
  
`;

const Heading = styled.h1`
  display: flex;
  margin: 0 auto;
  background: linear-gradient(to right, #ffa500, #ffd000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 80px;
`;
const H3 = styled.h3`
  text-align: center;
  //   white-space: pre-line;
  //   word-break: break-word;
  font-weight: normal;
  margin-bottom: 0;
`;
const Badge = styled.div`
  background: #ADD8E6;
  border-radius: 15px;
 display: inline-block; // or use 'inline-flex'
  padding: 1px 15px; 
  color: black;
   

`;
const CloseIcon = styled(RxCross1)`
cursor: pointer;
font-size: 12px;
margin-left: 5px;
`;




const options = [
  { value: "option1", label: "Option 1" },
  { value: "option2", label: "Option 2" },
  { value: "option3", label: "Option 3" },
];

const MainPage = ({setState}) => {
  const [selectedValue, setSelectedValue] = useState(options[0]);

  const handleValueChange = (selectedOption) => {
    setSelectedValue(selectedOption);
  };
  const handleBadgeClose = () => {
    alert('Badge closed!');
  };

  return (
    <CenteredContainer>
      <HeadingBox>
        <Heading> RTA</Heading>
        <h1>Careers at RTA</h1>
        <H3>
          We're excited to meet you. Outlined below are the current roles that
          RTA is looking to find new individuals to join our team.
        </H3>
      </HeadingBox>
      <Box>
        <h1>Job Openings</h1>
        <ContentWrapper>
          <SearchBar />
          
          {/* <FilterOuterBox>
          <FilterBox
            options={options}
            title=""
            selectedValue={selectedValue}
            onValueChange={handleValueChange}
           
          />
          <FilterBox
            options={options}
            title=""
            selectedValue={selectedValue}
            onValueChange={handleValueChange}
           
          />
          <FilterBox
          options={options}
          title=""
          selectedValue={selectedValue}
          onValueChange={handleValueChange}
         
        />
        <FilterBox
          options={options}
          title=""
          selectedValue={selectedValue}
          onValueChange={handleValueChange}
          marginRight={'0px'}
         
        />
        </FilterOuterBox> */}
        <br></br><br></br><br></br>
        
        <Badge>
        Pakistan
            <CloseIcon onClick={handleBadgeClose} />
        </Badge>
          <EmployeeTable setState={setState}/>
        </ContentWrapper>
      </Box>
      <Footer>
      <FormLabel style={{color:'#ffa500',marginTop:'10px'}}>
          View Webbsite • View All Jobs
        </FormLabel>
        
      </Footer> 
      <Footer>
        <FormLabel style={{color:'#ffa500',marginTop:'10px'}}>
        <span style={{ fontWeight: 'normal' }}>Powered By</span>RTA • Accessibility
        </FormLabel>
        
      </Footer> 
    </CenteredContainer>
  );
};
export default MainPage;
